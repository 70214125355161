@import 'src/general';

.container {
  max-width: 1320px;
  box-sizing: border-box;
  padding: 100px 50px;
  margin: auto;
  text-align: left;
  h1,
  h2 {
    color: $blue;
    font-family: 'Gothic', sans-serif;
    font-weight: 700;
  }
  h1 {
    margin-bottom: 100px;
    font-size: $subtitle-size;
    text-align: center;
  }
  h2 {
    margin: 35px 0;
    font-size: $text-size;
  }
  ul {
    padding-left: 25px;
    list-style: disc;
  }
  p,
  li {
    margin-bottom: 15px;
    font-family: 'Nunito', sans-serif;
    font-size: $small-text-size;
  }
  a {
    &::selection {
      color: $orange;
    }
    color: $orange;
  }
}

@include mobile {
  .container {
    padding: 100px 25px;
    li,
    p,
    a {
      font-size: 1rem;
    }
  }
}
