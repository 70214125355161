@import 'src/general';

.container {
  width: 100%;
  margin-bottom: 15px;
  color: red;
  font-family: 'Nunito', sans-serif;
  font-size: $text-size;
  font-weight: 700;
  text-align: left;
}
