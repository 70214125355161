@import 'src/general';

.gamePage {
  display: flex;
  flex-direction: column;
  justify-content: left;
  &__header {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    background-color: white;
    &__icon {
      width: 70px;
      margin: 0 50px;
      &--mobile {
        position: absolute;
        top: 30px;
        left: 40px;
        display: block;
        width: 60px;
      }
    }
  }

  &__game {
    position: absolute;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    &__object {
      width: 100%;
      height: 100%;
    }
  }
}

@include tablet {
  .gamePage {
    display: flex;
    flex-direction: row;
    &__header {
      position: absolute;
      display: flex;
      width: 80px;
      height: 100%;
      flex-direction: column;
      &__icon {
        width: 35px;
        margin: 15px 0;
        &--mobile {
          position: absolute;
          top: 25px;
          left: 25px;
          display: block;
          width: 30px;
        }
      }
    }
    &__game {
      position: absolute;
      top: 0;
      left: 80px;
    }
  }
}
