@import 'src/general';

.container {
  width: 100%;
  padding: 100px 0 40px 0;
  font-family: 'Nunito', sans-serif;
  text-align: center;

  h3 {
    margin-bottom: 16px;
    color: $blue;
    font-family: 'Gothic', sans-serif;
    font-size: $big-text-size;
    font-weight: 700;
  }
  h5 {
    max-width: 660px;
    margin: auto;
    color: $black;
    font-size: 1.375rem;
  }
}
