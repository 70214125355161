.container {
  display: flex;
  max-width: 190px;
  height: 120px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 2px solid transparent;
  margin: 0 15px;
  color: white;
  cursor: pointer;

  &.selected {
    border: 2px solid white;
  }

  .label {
    display: block;
    width: 70px;
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  input[type='radio'] {
    display: none;
  }
}
