@import 'src/general';

.container {
  display: flex;
  justify-content: space-evenly;
  img {
    width: 350px;
  }
  div {
    padding: 50px 0 0 0;
  }
}

@include small-tablet {
  .container {
    img {
      width: 220px;
    }
    div {
      padding: 0 0 0 25px;
    }
  }
}

@include mobile {
  .container {
    img {
      display: none;
    }
    div {
      width: 100%;
      padding: 0;
    }
  }
}
