$orange: #d6420c;
$orange-light: #e05a29;
$green: #00857a;
$green-light: #e5f3f1;
$blue: #482462;
$black: #333333;
$cream: #f1f1f1;

$title-size: 3.6rem;
$subtitle-size: 1.87rem;
$big-text-size: 1.62rem;
$text-size: 1.44rem;
$small-text-size: 1.25rem;

$mobile-screen: 576px;
$small-tablet-screen: 720px;
$tablet-screen: 1080px;

// Small devices
@mixin mobile {
  @media (max-width: #{$mobile-screen}) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: #{$small-tablet-screen}) {
    @content;
  }
}

// Medium devices
@mixin tablet {
  @media (max-width: #{$tablet-screen}) {
    @content;
  }
}

@mixin block {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 33px;
  border-radius: 8px;
  font-family: 'Nunito', sans-serif;
}

@mixin label($color) {
  .label {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 32px;
    color: $color;
    font-family: 'Gothic', sans-serif;
    font-size: $text-size;
    font-weight: 700;
    .icon {
      display: block;
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-right: 10px;
    }
  }
}

@mixin green-block {
  @include block;
  @include label(white);
  background-color: $green;
  color: white;
}

@mixin white-block {
  @include block;
  @include label($blue);
  background-color: white;
  color: $black;
}
