@import 'src/general';

.container {
  // position: relative;
  display: flex;
  max-width: 1320px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin: auto;

  .img {
    display: flex;
    width: 100%;
    height: 575px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-image: url('../../assets/images/flex_illustration.png');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .texts {
    // padding: 0 25%;
    color: $blue;
    font-family: 'Gothic', sans-serif;
    font-size: $title-size;
    font-weight: 700;
    text-align: left;
    p {
      margin-bottom: 50px;
    }
  }
}

@include tablet {
  .container {
    .texts {
      padding: 0 5%;
      font-size: $big-text-size;
      p {
        margin-bottom: 20px;
      }
    }
  }
}

@include small-tablet {
  .container {
    flex-direction: column-reverse;
    .texts {
      padding: 0 5%;
      text-align: center;
      div {
        margin: 50px auto;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
}
