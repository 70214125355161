@import 'src/general';

$btn-radius: 20px;
.container {
  width: 49%;
  @include green-block;

  .icon {
    background-image: url('../../assets/images/duration.svg');
  }
}
.counter {
  display: flex;
  width: 190px;
  flex-direction: column;

  svg {
    width: 100%;
    height: 100%;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }

  .circular-chart {
    display: block;
    width: 100%;
    height: 100%;

    .circle-bg {
      fill: none;
      stroke: white;
      stroke-width: 3.8;
    }

    .circle {
      fill: none;
      stroke: $blue;
      stroke-linecap: round;
      stroke-width: 2.8;
    }

    .percentage {
      fill: white;
      font-family: 'Nunito', sans-serif;
      font-size: 0.5em;
      font-weight: 700;
      text-anchor: middle;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
button {
  width: 70px;
  height: 45px;
  border: 2px solid white;
  background-color: $blue;
  color: white;
  cursor: pointer;
  font-size: $big-text-size;
  font-weight: 700;
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
  &.less {
    border-bottom-left-radius: $btn-radius;
    border-top-left-radius: $btn-radius;
  }
  &.more {
    border-bottom-right-radius: $btn-radius;
    border-top-right-radius: $btn-radius;
  }
}

@include tablet {
  .container {
    width: 100%;
    padding: 20px;
  }
}
