@import 'src/general';

.container {
  display: flex;
  width: 80%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: $blue;
  font-family: 'Nunito', sans-serif;
  font-size: $text-size;
  font-weight: 700;
  text-align: center;
  img {
    max-width: 450px;
    margin-bottom: 80px;
  }
}
