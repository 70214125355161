@import 'src/general';

.container {
  display: flex;
  max-width: 1320px;
  flex-direction: column;
  padding: 0 15px;

  .cgu {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
    }
  }
}
.btn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.block {
  display: flex;
  height: 190px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 25px;
  background-color: $green;
  p:first-child {
    margin: 20px;
    color: white;
    font-size: $big-text-size;
    font-weight: 700;
  }
}
.block_1 {
  width: 35%;
}
.block_2 {
  width: 63%;
}
.block_3 {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.radio {
  display: flex;
}

@include small-tablet {
  .container {
    width: 100%;
    box-sizing: border-box;

    .line {
      flex-direction: column;
    }
    .block_1 {
      margin-bottom: 20px;
    }
    .block {
      width: 100%;
    }
  }
}

@include mobile {
  .block {
    height: auto;
    padding: 25px 0;
    .radio {
      flex-direction: column;
    }
  }
}
