@import 'src/general';

.button {
  display: flex;
  width: 222px;
  height: 56px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  margin: auto;
  background: $orange 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 1.1rem;
  font-weight: normal;
  letter-spacing: 0;
  opacity: 1;
  text-align: center;

  &:hover {
    background-color: $orange-light;
  }

  &--reversed {
    border: 1px solid $orange;
    background: #ffffff;
    color: $orange;
    &:hover {
      color: white;
    }
  }

  &--small {
    width: 128px;
    height: 35px;
    padding: 0;
    font-size: 14px;
  }

  .loader {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: auto;
  }
  .loader div {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: 8px solid;
    border-color: #fff transparent transparent transparent;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-radius: 50%;
  }
  .loader div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@include mobile {
  .button {
    &--small {
      width: 100px;
      height: 35px;
      font-size: 14px;
    }
  }
}
