@import 'src/general';

body {
  --swiper-theme-color: #482462;
}

.carousel-container {
  position: relative;
  max-width: 960px;
  margin: auto;
  .swiper-pagination-bullet-active {
    transform: scale(1.5);
    transition: transform ease 0.2s;
  }
}

.icon {
  position: absolute;
  top: 150px;
  width: 18px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  &--next {
    right: -30px;
    background-image: url('../../assets/images/icon-next.png');
  }
  &--prev {
    left: -30px;
    background-image: url('../../assets/images/icon-prev.png');
  }

  &.swiper-button-disabled {
    opacity: 0.4;
  }
}

.swiper-container {
  width: 940px;
  padding: 0 10px 60px 10px;
}

@media (max-width: 1050px) {
  .carousel-container {
    max-width: 640px;
  }

  .swiper-container {
    width: 620px;
  }
}

@media (max-width: 720px) {
  .carousel-container {
    max-width: 320px;
  }
  .swiper-container {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 450px) {
  .swiper-container {
    width: 100%;
    max-width: 280px;
  }
  .icon {
    display: none !important;
  }
}
