@import 'src/general';

.gameInfo {
  position: relative;
  display: flex;
  width: 90%;
  max-width: 1080px;
  flex-direction: row;
  padding: 32px;
  margin: auto;
  background: white;
  border-radius: 11px;
  box-shadow: 0 6px 8px #00000073;
  opacity: 1;
  user-select: none;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 29px;
    height: 29px;
    background-image: url('../../assets/images/close.svg');
    background-size: cover;
    cursor: pointer;
  }

  &__infoContainer {
    display: flex;
    min-width: calc(50% - 32px);
    max-width: 550px;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 60px 0 70px;
  }

  &__image {
    width: 100%;
    max-width: 512px;
  }

  &__title {
    margin-bottom: 8px;
    color: #482462;
    font-family: 'Gothic', sans-serif;
    font-size: 1.62rem;
    font-weight: 700;
  }

  &__category {
    margin-bottom: 16px;
    color: #000000;
    font-size: 1.62rem;
    letter-spacing: 0;
  }

  &__description {
    font-size: 1.3rem;
    text-align: left;
  }

  &__button {
    width: 222px;
    margin-top: 32px;
  }
}

@include tablet {
  .gameInfo {
    display: flex;
    flex-direction: column;
    &__infoContainer {
      max-width: unset;
      padding: 0;
    }
    &__imageContainer {
      margin-bottom: 16px;
      text-align: center;
    }
    &__title {
      text-align: center;
    }

    &__category {
      text-align: center;
    }
    &__button {
      width: 100%;
    }
  }
}
