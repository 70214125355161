@import 'src/general';

.container {
  display: flex;
  max-width: 1320px;
  flex-direction: column;
  align-items: center;
  .blocks {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

@include tablet {
  .container {
    width: 100%;
    box-sizing: border-box;
    .blocks {
      flex-direction: column;
    }
  }
}
