@import 'src/general';

.header {
  display: flex;
  background-color: white;
  a {
    display: flex;
    align-items: center;
  }
  &.top {
    width: 100%;
    height: 190px;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 50px;
      &.icon-home {
        display: none;
      }
    }
    @include mobile {
      height: 80px;
      img {
        width: 60px;
        height: auto;
        margin: 0 25px;
      }
    }
  }

  &.left {
    position: relative;
    width: 80px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 35px;
      margin: 15px 0;
      &.icon-home {
        position: absolute;
        top: 0;
        width: 30px;
      }
    }
  }
}
