.gameCard {
  width: 100%;
  max-width: 300px;
  height: 367px;
  margin: auto;
  background: #ffffff 0 0 no-repeat padding-box;
  border-radius: 15px;
  box-shadow: 0 0 10px #00000052;
  user-select: none;

  &__image {
    width: 100%;
    height: 145px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px 15px 0 0;
  }

  &__gameTitle {
    height: 5%;
    margin: 20px 0 0 16px;
    color: #482462;
    font-family: 'Nunito', sans-serif;
    font-size: 1.375rem;
    font-weight: bold;
    letter-spacing: 0;
  }

  &__gameDescription {
    height: 70px;
    margin: 20px 16px 0 16px;
    color: #000000;
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0;
    text-align: left;
  }

  &__buttonsContainer {
    display: inline-block;
    width: 50%;
    margin-top: 25px;
    text-align: center;
  }
}
