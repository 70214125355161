@import 'src/general';

.container {
  padding-bottom: 80px;
  background: $green-light;
  .content {
    max-width: 1320px;
    margin: auto;
  }
}
