@import 'src/general';

.container {
  width: 100%;

  .links-logos {
    display: flex;
    width: 100%;
    height: 250px;
    align-items: center;
    justify-content: center;
    background-color: $cream;
    div:first-of-type {
      display: flex;
      width: 100%;
      max-width: 1320px;
      margin: auto;

      div {
        width: 50%;
        box-sizing: border-box;
        padding: 0 25px;
        img:first-of-type {
          margin-right: 80px;
        }
      }
      ul {
        width: 50%;
        margin-left: 150px;
        text-align: left;
        li {
          margin: 15px 0;
        }
        a {
          color: $black;
          font-size: $small-text-size;
          text-decoration: none;
        }
      }
    }
  }

  .copyright {
    display: flex;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    text-align: center;
  }
}

@include small-tablet {
  .container {
    .links-logos {
      div:first-of-type {
        flex-direction: column;
        div {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          flex-direction: row;
          justify-content: space-evenly;
          padding: 0 25px 25px 25px;

          img:first-of-type {
            margin-right: 0;
          }
        }
        ul {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0;
          text-align: left;
          li {
            margin: 0 0 15px 0;
            &:last-of-type {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
