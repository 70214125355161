@import 'src/general';

.container {
  position: relative;
  z-index: 1;
  max-width: 1320px;
  padding: 0 10px 50px;
  margin: auto;
}

.green {
  position: relative;
  background-color: $green-light;
}

.circle-part {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 113px;
  height: 113px;
  background-image: url('../../assets/images/circle-part.png');
  background-repeat: no-repeat;
}
