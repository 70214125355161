@font-face {
  font-display: swap;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bold;
  src: url('./Nunito-Bold/Nunito-Bold.eot');
  src: local('Nunito Bold'), local('Nunito-Bold'),
    url('./Nunito-Bold/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Nunito-Bold/Nunito-Bold.woff2') format('woff2'),
    url('./Nunito-Bold/Nunito-Bold.woff') format('woff'),
    url('./Nunito-Bold/Nunito-Bold.ttf') format('truetype'),
    url('./Nunito-Bold/Nunito-Bold.svg#Nunito-Bold') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  src: url('./Nunito-Regular/Nunito-Regular.eot');
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('./Nunito-Regular/Nunito-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./Nunito-Regular/Nunito-Regular.woff2') format('woff2'),
    url('./Nunito-Regular/Nunito-Regular.woff') format('woff'),
    url('./Nunito-Regular/Nunito-Regular.ttf') format('truetype'),
    url('./Nunito-Regular/Nunito-Regular.svg#Nunito-Regular') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Gothic';
  font-style: normal;
  font-weight: normal;
  src: url('./Gothic/GOTHIC.TTF') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Gothic';
  font-style: normal;
  font-weight: bold;
  src: url('./Gothic/GOTHICB.TTF') format('truetype');
}
