@import 'src/general';

/** Mixins **/
@mixin track {
  height: 15px;
  box-sizing: border-box;
  border: 2px solid $orange;
  border-radius: 15px;
}

@mixin thumb {
  position: relative;
  top: -7px;
  width: 25px;
  height: 25px;
  border: none;
  -webkit-appearance: none;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

/** range container **/
.container {
  width: 100%;
}
.input {
  display: none;
}
.range {
  width: 90%;
  margin: auto;
}

/** Tooltip current value **/
.sliderValue {
  display: flex;
  width: 100%;
  height: 25px;
  box-sizing: border-box;
  padding: 0 11px;
  span {
    position: relative;
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 2px solid $orange;
    background-color: white;
    color: $green;
    font-weight: 700;
    transform: translateX(-50%);

    &::after {
      position: absolute;
      bottom: -11px;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-top: 9px solid #d6420c;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      content: '';

      transform: translateX(-50%);
    }
  }
}

/** Legend (min / max value) **/
.field {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  .value {
    position: absolute;
    top: 40px;
    color: white;
    font-size: 24px;
    &.left {
      left: -10px;
    }
    &.right {
      right: -10px;
    }
  }
}
.fill {
  position: absolute;
  left: 0;
  width: 50px;
  height: 15px;
  box-sizing: border-box;
  background-color: $orange;
  border-radius: 5px;
}

/* Input skin **/
input[type='range'] {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 15px;
  border: none;
  margin: 0;
  -webkit-appearance: none;
  background: transparent;
  border-radius: 5px;
  outline: none;
}
input::-webkit-slider-runnable-track {
  @include track;
}
input::-moz-range-track {
  @include track;
}
input::-webkit-slider-thumb {
  @include thumb;
}
input::-moz-range-thumb {
  @include thumb;
}
input::-moz-range-progress {
  height: 100%;
  background-color: $orange;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

/** More / Less icons **/
.icons {
  position: absolute;
  width: 100%;
  height: 30px;

  .icon {
    position: absolute;
    z-index: 0;
    display: block;
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    &.left {
      left: -50px;
    }
    &.right {
      right: -50px;
    }
  }
}

@include mobile {
  .slider {
    display: none;
  }
  .input {
    display: block;
    input {
      width: 80%;
      height: 70px;
      border: none;
      margin: auto;
      background-color: white;
      border-radius: 8px;
      font-size: $big-text-size;
      outline: none;
      text-align: center;
    }
  }
}
