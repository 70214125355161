@import 'src/general';

.container {
  width: 49%;
  @include green-block;

  .fullname {
    display: block;
  }
  .abbrev {
    display: none;
  }
  .icon {
    background-image: url('../../assets/images/frequence.svg');
  }
  .text {
    margin-bottom: 32px;
    font-size: $small-text-size;
  }
  .days {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      display: flex;
      width: 120px;
      height: 50px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border: 2px solid $blue;
      margin: 10px 13px;
      background-color: white;
      border-radius: 8px;
      color: $blue;
      cursor: pointer;
      font-weight: 700;

      &.selected {
        background-color: $blue;
        color: white;
      }
    }
  }
}

@include tablet {
  .container {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    .fullname {
      display: none;
    }
    .abbrev {
      display: block;
    }
    .days {
      div {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        font-size: $big-text-size;
      }
    }
  }
}
