@import 'src/general';

.container {
  width: 100%;
  margin-bottom: 32px;
  @include white-block;
  .icon {
    background-image: url('../../assets/images/ampule.svg');
  }
  ol {
    width: 100%;
    box-sizing: border-box;
    padding-left: 50px;
    color: $black;
    font-size: $small-text-size;
    li {
      display: flex;
      margin-bottom: 20px;
      /** number **/
      span:first-of-type {
        display: flex;
        width: 26px;
        min-width: 26px;
        height: 26px;
        align-items: center;
        justify-content: center;
        background-color: $orange;
        border-radius: 50%;
        color: white;
      }
      /** text **/
      span:last-of-type {
        margin-left: 20px;
      }
    }
  }
  @include mobile {
    ol {
      padding: 0;
    }
  }
}
