@import 'src/general';

.headline {
  position: relative;
  height: 490px;
  padding: 80px 80px 0;
  line-height: calc(#{$title-size} + 30px);

  &__content {
    position: relative;
    max-width: 970px;
    margin-left: 70px;
    color: $blue;
    font-family: 'Gothic', sans-serif;
    font-size: $title-size;
    font-weight: 700;
    text-align: left;
    &::after {
      display: block;
      width: 100px;
      height: 5px;
      margin-top: 28px;
      background-color: $orange;
      content: '';
    }
  }
  &__img {
    position: absolute;
    top: 0;
    right: 80px;
    width: 45%;
    max-width: 1080px;
    height: auto;
  }
}

@media (max-width: 1600px) {
  .headline {
    height: auto;
    padding: 0;
    line-height: calc(#{$subtitle-size} + 10px);
    &__content {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      margin: auto;
      font-size: $subtitle-size;
      text-align: center;
      &::after {
        margin: 25px auto 0;
      }
    }
    img {
      position: unset;
      display: block;
      min-width: 90%;
      margin: auto;
    }
  }
}
