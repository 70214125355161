.modal-root {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 25px 10px;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-x: hidden;
    overflow-y: auto;
  }
  &--hidden {
    display: none;
  }
}
